import styled from "styled-components"

export const AdminPageContent = styled.section`
    /* background: var(--background-color); */
    margin:10px;

`
export const AdminPageContainer = styled.section`
    background: var(--background-color);
    height:100%;
    width:100%;
    padding-bottom:10px;
    /* margin:10px; */

`