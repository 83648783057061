import React from "react";
import ModalDetail from "../components/ModalDetail";

export const Test = () => {
	return (
		<div>
			<ModalDetail />
		</div>
	);
};
